
.content-card{
    box-shadow: 4px 4px 10px grey;
    background-color: var(--light3);
}

.content-card:hover{    
    /* transform: scale(1.05); */
    
    animation-name: zoom;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes zoom{
    from{
        transform: scale(1);
        box-shadow: 4px 4px 10px grey;
    }
    to{
        transform: scale(1.03);
        box-shadow: 10px 10px 20px grey;
        background-color: var(--light1);
        color: var(--dark2);
        text-shadow: 10px 10px 15px var(--dark2);
    }
}