
.content-container{
    width: calc(100% - 8em);
    max-width: 1600px;
    min-width: 200px;
    margin: 0 auto 0;

    padding: 1em;

    box-shadow: 5px 5px 20px var(--grey);
    border-radius: 4px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
}

@media only screen and (max-width: 850px){
    .content-container{
        width: calc(100% - 2em);
    }
}

.sliding-container{
    position: relative;
    animation-name: slide-in;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1); 
    animation-fill-mode: forwards;
}

@keyframes slide-in{
    from{
        right: 75em;

    }
    to{
        right: 0em;
    }
}