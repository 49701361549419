.event-box{

    min-width: 200px;
    max-width: 600px;
    padding: 1em;

    box-shadow: 5px 5px 10px var(--grey);

    text-align: left;

    display: grid;
    grid-template-columns: 1fr 2fr;  
    grid-template-rows: auto auto;
    grid-template-areas: 
    'image heading'
    'image description';

    grid-gap: .75em;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
}

@media only screen and (max-width: 480px){
    .event-box{
        grid-template-rows: 2fr auto 1fr;
        grid-template-columns: auto;  
        grid-template-areas: 
        'image'
        'heading'
        'description';
    }
}








/* EVENT IMAGE */

.event-image{
    grid-area: image;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;

    margin: 0 auto;
}


/* EVENT HEADING */
.event-heading{
    grid-area: heading;
}

.event-heading h3, .event-heading h5{
    padding: 0;
    margin: 0;
}




/* EVENT DESCRIPTION */
.event-description{
    grid-area: description;
}

