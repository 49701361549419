
.project-icon-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
}

@media only screen and (max-width: 500px){
    .project-icon-container{
        grid-template-columns: 1fr;
    }
}