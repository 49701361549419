
.blog-post-container{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
    
    text-align: left;

    padding: 1em 2em;

    word-wrap: break-word;

}

img{
    display: block;
    max-width: 100%;
    margin: 0 auto;
}
