:root{
    --grey: #616161;
    
    --light1: #f5f5f5;
    --light2: #ebebeb;
    --light3: #fafafa;

    --dark1: #1a1a1a;
    --dark2: #363636;

    --clear: #ffffff00;
}