
.navbar a{
    text-decoration: none;
    color: var(--light1);
    padding: 1em 1.5em;
}

/* DESKTOP NAVBAR */

.desktop-navbar{
    width: calc(100% - 8em);
    max-width: 1600px;
    margin: 0 auto 2em;
    padding: 3em 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    
    background-color: var(--dark1);
}

.desktop-navbar a{
    border: 1px solid var(--light1);
    border-radius: 8px; 
}

.desktop-navbar a:hover{
    animation-name: nav-mouseover;
    animation-duration: 0.50s;
    animation-fill-mode: forwards;
    font-weight: 300;
}

@keyframes nav-mouseover{
    from{
        background-color: var(--dark1);
        color: var(--light1);
    }
    to{
        background-color: var(--light1); 
        color: var(--dark1);
        letter-spacing: .35em;
        border-color: var(--dark1);
        box-shadow: 1px 1px 5px var(--grey) inset;
        text-shadow: 2px 2px 5px var(--grey); 
    }
}

@media only screen and (max-width: 1100px){
    .desktop-navbar div{
        /* display: none; */
        transform: scale(0.78);
    }
}

@media only screen and (max-width: 850px){
    .desktop-navbar{
        display: none;
    }
}


/* MOBILE  NAVBAR */

@media only screen and (min-width: 851px){
    .mobile-navbar-toggle{
        display: none;
    }
}

.mobile-navbar-toggle{
    background-color: var(--dark1);
    padding: .25em 0;
    margin-bottom: 0.5em;
}

.hamburger-icon{
    background-color: var(--light1);
    height: 3px;
    width: 1.25em;
    margin: 4px auto;
}

.mobile-nav-visible-true{
    display: block;
    margin-bottom: 0.5em;
}

.mobile-nav-visible-false{
    display: none;
}

.mobile-nav-item{
    padding: 1em;
    background-color: var(--dark1);
    margin: 0 1em;
    border-bottom: 1px solid var(--light1);
}