

.content-selector-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1em;
}

@media only screen and (max-width: 1050px){
    .content-selector-container{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 800px){
    .content-selector-container{
        grid-template-columns: 1fr;
    }
}

.content-selector-card{
    max-height: 20em;
    padding: 1em;
    color: black;
}

.content-selector-link{
    text-decoration: none;
}



