body{
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 

  padding: 0 0 4em;

  min-height: 1080px;
}

.App {
  text-align: center;
}



