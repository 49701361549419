body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root{
    --grey: #616161;
    
    --light1: #f5f5f5;
    --light2: #ebebeb;
    --light3: #fafafa;

    --dark1: #1a1a1a;
    --dark2: #363636;

    --clear: #ffffff00;
}
body{
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  box-sizing: border-box; 

  padding: 0 0 4em;

  min-height: 1080px;
}

.App {
  text-align: center;
}






.bio-box{
    text-align: left;
    min-height: 20em;
}

.bio-version{
    /* text-align: center; */
}

.headshot-image{
    width: 100%;
    max-width: 12em;
    border-radius: 10px;
    float: left;
    
    margin: 1em 1em 0;
}

.social-icon{
    height: 1.5em;
    width: 1.5em;
    margin: 0 0.5em 0 0;

    display: inline-block;

    cursor: pointer;
}

@media only screen and (max-width: 700px){
    .headshot-image{
        float: none;
        display: block;
        width: 100%;
        margin: 0 auto;
    }
}


.landing-container{
    height: 30em;
    /* background-image: url(''); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: calc(100% - 8em);
    max-width: 1600px;
    margin: 0 auto;

}

@media only screen and (max-width: 850px){
    .landing-container{
        width: 100%;
        margin: 0;
    }
}

/* LANDING TEXT CONTAINER */

.landing-text{
    
    background-color: white;
    height: 100%;
    width: 100%;
    
    -webkit-animation-name: reveal-background;
    
            animation-name: reveal-background;
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; 
    
    display: flex;
    justify-content: center;
    align-items: center;
}

@-webkit-keyframes reveal-background{
    from{
        background-color: white;
    }
    to{
        background-color: rgba(212, 212, 212, 0.507);
    }
}

@keyframes reveal-background{
    from{
        background-color: white;
    }
    to{
        background-color: rgba(212, 212, 212, 0.507);
    }
}

/* LANDING TITLE */

.landing-title, .landing-subtitle{
    font-weight: 300;
    font-family: Arial, Helvetica, sans-serif;
    
    -webkit-animation-name: title-reveal;
    
            animation-name: title-reveal;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    
    letter-spacing: 2em;
    color: var(--clear);
    
    white-space: nowrap;

}


@media only screen and (min-width: 1001px){
    .landing-title{
        font-size: 4em;
        
        -webkit-animation-duration: 7s;
        
                animation-duration: 7s;
        -webkit-animation-delay: 1s;
                animation-delay: 1s;
        
    }
    
    .landing-subtitle{
        font-size: 1.5em;
        
        -webkit-animation-duration: 7s;
        
                animation-duration: 7s;
        -webkit-animation-delay: 2s;
                animation-delay: 2s;
    }
} 


@-webkit-keyframes title-reveal{
    0%{
        letter-spacing: 1.25em;
        color: var(--clear);
    }
    100%{
        letter-spacing: 0.5em;
        color: var(--dark);
        text-shadow: 1px 1px 5px var(--grey);
    }
} 


@keyframes title-reveal{
    0%{
        letter-spacing: 1.25em;
        color: var(--clear);
    }
    100%{
        letter-spacing: 0.5em;
        color: var(--dark);
        text-shadow: 1px 1px 5px var(--grey);
    }
}

@-webkit-keyframes title-reveal-mobile{
    0%{
        letter-spacing: 0.6em;
        color: var(--clear);
    }
    100%{
        letter-spacing: 0.3em;
        color: var(--dark1);
        text-shadow: 1px 1px 5px var(--grey);
    }
}

@keyframes title-reveal-mobile{
    0%{
        letter-spacing: 0.6em;
        color: var(--clear);
    }
    100%{
        letter-spacing: 0.3em;
        color: var(--dark1);
        text-shadow: 1px 1px 5px var(--grey);
    }
}

@media only screen and (max-width: 1300px){
    .landing-title, .landing-subtitle{
        font-weight: 300;
        font-family: Arial, Helvetica, sans-serif;
        color: var(--clear);
        letter-spacing: 0.25em;
        
        -webkit-animation-name: title-reveal-mobile;
        
                animation-name: title-reveal-mobile;
        -webkit-animation-duration: 5s;
                animation-duration: 5s;
        -webkit-animation-delay: 1s;
                animation-delay: 1s;
    }
}

@media only screen and (max-width: 730px){
    .landing-title, .landing-subtitle{
        letter-spacing: 1px;
        color: var(--dark1); 
        -webkit-animation-name: none; 
                animation-name: none;
        white-space: normal;
        text-shadow: 1px 1px 5px var(--grey);

    }
}

.navbar a{
    text-decoration: none;
    color: var(--light1);
    padding: 1em 1.5em;
}

/* DESKTOP NAVBAR */

.desktop-navbar{
    width: calc(100% - 8em);
    max-width: 1600px;
    margin: 0 auto 2em;
    padding: 3em 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    
    background-color: var(--dark1);
}

.desktop-navbar a{
    border: 1px solid var(--light1);
    border-radius: 8px; 
}

.desktop-navbar a:hover{
    -webkit-animation-name: nav-mouseover;
            animation-name: nav-mouseover;
    -webkit-animation-duration: 0.50s;
            animation-duration: 0.50s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    font-weight: 300;
}

@-webkit-keyframes nav-mouseover{
    from{
        background-color: var(--dark1);
        color: var(--light1);
    }
    to{
        background-color: var(--light1); 
        color: var(--dark1);
        letter-spacing: .35em;
        border-color: var(--dark1);
        box-shadow: 1px 1px 5px var(--grey) inset;
        text-shadow: 2px 2px 5px var(--grey); 
    }
}

@keyframes nav-mouseover{
    from{
        background-color: var(--dark1);
        color: var(--light1);
    }
    to{
        background-color: var(--light1); 
        color: var(--dark1);
        letter-spacing: .35em;
        border-color: var(--dark1);
        box-shadow: 1px 1px 5px var(--grey) inset;
        text-shadow: 2px 2px 5px var(--grey); 
    }
}

@media only screen and (max-width: 1100px){
    .desktop-navbar div{
        /* display: none; */
        transform: scale(0.78);
    }
}

@media only screen and (max-width: 850px){
    .desktop-navbar{
        display: none;
    }
}


/* MOBILE  NAVBAR */

@media only screen and (min-width: 851px){
    .mobile-navbar-toggle{
        display: none;
    }
}

.mobile-navbar-toggle{
    background-color: var(--dark1);
    padding: .25em 0;
    margin-bottom: 0.5em;
}

.hamburger-icon{
    background-color: var(--light1);
    height: 3px;
    width: 1.25em;
    margin: 4px auto;
}

.mobile-nav-visible-true{
    display: block;
    margin-bottom: 0.5em;
}

.mobile-nav-visible-false{
    display: none;
}

.mobile-nav-item{
    padding: 1em;
    background-color: var(--dark1);
    margin: 0 1em;
    border-bottom: 1px solid var(--light1);
}

.content-card{
    box-shadow: 4px 4px 10px grey;
    background-color: var(--light3);
}

.content-card:hover{    
    /* transform: scale(1.05); */
    
    -webkit-animation-name: zoom;
    
            animation-name: zoom;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes zoom{
    from{
        transform: scale(1);
        box-shadow: 4px 4px 10px grey;
    }
    to{
        transform: scale(1.03);
        box-shadow: 10px 10px 20px grey;
        background-color: var(--light1);
        color: var(--dark2);
        text-shadow: 10px 10px 15px var(--dark2);
    }
}

@keyframes zoom{
    from{
        transform: scale(1);
        box-shadow: 4px 4px 10px grey;
    }
    to{
        transform: scale(1.03);
        box-shadow: 10px 10px 20px grey;
        background-color: var(--light1);
        color: var(--dark2);
        text-shadow: 10px 10px 15px var(--dark2);
    }
}


.content-selector-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1em;
}

@media only screen and (max-width: 1050px){
    .content-selector-container{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 800px){
    .content-selector-container{
        grid-template-columns: 1fr;
    }
}

.content-selector-card{
    max-height: 20em;
    padding: 1em;
    color: black;
}

.content-selector-link{
    text-decoration: none;
}







.score-frame{
    width: 100%;
    min-height: 45em;
}

.listen-info-box{
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--dark2);

    padding: 0;
}

.listen-info-box-image-cover{
    background-color: rgba(0, 0, 0, 0.50);
    color: var(--light1) !important;
}

.listen-info-box-image-cover:hover{
    -webkit-animation-name: image-cover;
            animation-name: image-cover;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes image-cover{ 
    to{
        background-color: rgba(255, 255, 255, 0.65);
        color: var(--dark2);
        letter-spacing: 0.1em;
    }
}

@keyframes image-cover{ 
    to{
        background-color: rgba(255, 255, 255, 0.65);
        color: var(--dark2);
        letter-spacing: 0.1em;
    }
}

.project-links a{
    margin: 0 0.5em;
}

.project-info{
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.project-tech-badge{
    height: 2.5em;
    width: 2.5em;
    margin: 0;

    display: inline-block;

    background-size: 100% 100%;
}

.project-icon-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
}

@media only screen and (max-width: 500px){
    .project-icon-container{
        grid-template-columns: 1fr;
    }
}
.project-icon-container{
	display: grid;
	margin: 0 auto;
}

.icon-container{
	margin: 1em auto;
	cursor: pointer; 
}


.icon-clicked-true{
	transform: scale(1.1);
	-webkit-animation-name: enlarge-icon;
	        animation-name: enlarge-icon;
	-webkit-animation-duration: .35s;
	        animation-duration: .35s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes enlarge-icon{
	from{
		transform: scale(1);
	}
	to{
		transform: scale(2.5);
	}
}

@keyframes enlarge-icon{
	from{
		transform: scale(1);
	}
	to{
		transform: scale(2.5);
	}
}

.icon-body{
	background-color: #616161;
	box-shadow: 0 25px 50px #a7a7a7;
}

.image-screen{
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	margin: 0 auto;
    background-color: white;
    background-size: 100% 100%;
    background-repeat: no-repeat;
	border-radius: 2px;
	
	box-shadow: 1px 1px 10px white inset;
}







.tablet-screen-frame{
	height: 8em;
	width: 6em;
	margin: 0 auto;
	padding: .5em 0.25em 0.75em;
	border-radius: 8px;
}

.tablet-button{
	height: .4em;
	width: .4em;
	background-color: #9b9b9b;
	margin: .2em auto 0;
	border-radius: 50%;
}




.mobile-screen-frame{
	height: 5.5em;
	width: 3.5em;
	margin: 1em auto;
	padding: 0.75em .1em .35em;
	border-radius: 10px;
}




.laptop-screen-frame{
	height: 7em;
	width: 10em;
	margin: 0 auto;
	
	padding: .5em .4em;
	border-radius: 8px
}

.laptop-bass{
	height: 0.5em;
	width: 13em;
	margin: 0 auto;
	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 30px;
}


.content-container{
    width: calc(100% - 8em);
    max-width: 1600px;
    min-width: 200px;
    margin: 0 auto 0;

    padding: 1em;

    box-shadow: 5px 5px 20px var(--grey);
    border-radius: 4px;
    box-sizing: border-box; 
}

@media only screen and (max-width: 850px){
    .content-container{
        width: calc(100% - 2em);
    }
}

.sliding-container{
    position: relative;
    -webkit-animation-name: slide-in;
            animation-name: slide-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1); 
    -webkit-animation-fill-mode: forwards; 
            animation-fill-mode: forwards;
}

@-webkit-keyframes slide-in{
    from{
        right: 75em;

    }
    to{
        right: 0em;
    }
}

@keyframes slide-in{
    from{
        right: 75em;

    }
    to{
        right: 0em;
    }
}
.event-box{

    min-width: 200px;
    max-width: 600px;
    padding: 1em;

    box-shadow: 5px 5px 10px var(--grey);

    text-align: left;

    display: grid;
    grid-template-columns: 1fr 2fr;  
    grid-template-rows: auto auto;
    grid-template-areas: 
    'image heading'
    'image description';

    grid-gap: .75em;
    box-sizing: border-box; 
}

@media only screen and (max-width: 480px){
    .event-box{
        grid-template-rows: 2fr auto 1fr;
        grid-template-columns: auto;  
        grid-template-areas: 
        'image'
        'heading'
        'description';
    }
}








/* EVENT IMAGE */

.event-image{
    grid-area: image;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;

    margin: 0 auto;
}


/* EVENT HEADING */
.event-heading{
    grid-area: heading;
}

.event-heading h3, .event-heading h5{
    padding: 0;
    margin: 0;
}




/* EVENT DESCRIPTION */
.event-description{
    grid-area: description;
}




.resume-button{
    cursor: pointer;
    margin: 1em 0.5em 0;
    background-color: var(--light1);
    border: none;
    box-shadow: 2px 2px 10px var(--grey);
    padding: 0 0.5em;
    border-radius: 8px;
}

.resume-button:hover{
    -webkit-animation-name: res-btn-mouse;
            animation-name: res-btn-mouse;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes res-btn-mouse{
    from{
        box-shadow: 2px 2px 10px var(--grey);
    }
    to{
        box-shadow: 5px 5px 20px var(--grey);
        letter-spacing: 0.1em;
        text-shadow: 5px 5px 15px var(--grey);
    }
}

@keyframes res-btn-mouse{
    from{
        box-shadow: 2px 2px 10px var(--grey);
    }
    to{
        box-shadow: 5px 5px 20px var(--grey);
        letter-spacing: 0.1em;
        text-shadow: 5px 5px 15px var(--grey);
    }
}

.resume-item-skills_and_technologies{
    max-width: 20em;
}

.resume-item-education{
    max-width: 25em;
}

.inner-content-body{
    text-align: left;
    margin: 1em auto 0;
    padding: 0.25em 1em;
    width: 75%;
    min-width: 200px;

    box-shadow: 3px 3px 8px var(--grey) inset;
    border-radius: 4px;
}

@media only screen and (max-width: 1300px){
    .inner-content-body{
        width: 95%;
    }
}

@media only screen and (max-width: 700px){
    .inner-content-body{
        padding: 1em;
        width: 100%;
        box-sizing: border-box; 
    }
}


/* CONTACT FORM GENERAL STYLES */
.contact-form{
    display: grid;

    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(3, auto);

    grid-template-areas: 
    'name statement statement'
    'email statement statement'
    'subject subject .'
    'message message message'
    'submit . .';

    text-align: left;
}

.contact-form input{
    width: calc(100% - 2em);
    /* border: 2px solid black; */
    border: none;
    box-shadow: 5px 5px 20px var(--grey);
    border-radius: 1px;
    padding: .5em;

    font-size: 1.3em;
}

.contact-form label h3{
    margin: 1em 0 -.5em;
}

.contact-form input[type="submit"]{
    cursor: pointer;
}


/* GRID AREAS */

.contact-statement{
    grid-area: statement;
    margin: 2em auto 0;
    padding: 0 0 1em 1em;
    max-width: 1000px;
    border-left: 1px solid var(--dark1);
}

.contact-form-name{
    grid-area: name;
}

.contact-form-email{
    grid-area: email;
}

.contact-form-subject{
    grid-area: subject;
}

.contact-form-message{
    grid-area: message;
}

.contact-form-submit{
    grid-area: submit;
}



/* MESSAGE CSS */

.contact-form-message textarea{
    width: calc(100% - 3em);
    height: 10em;
    margin-bottom: 1em;

    border-radius: 1px;
    border: none;
    box-shadow: 3px 3px 10px var(--grey);
    padding: 1em;
    font-size: 1.5em;
}



@media only screen and (max-width: 1000px){
    .contact-form{
        /* transform: scale(0.95); */
        grid-template-rows: repeat(6, auto);
        grid-template-columns: auto;
        padding: 0;
        /* grid-template-areas:
        'name name name'
        'email email email'
        'subject subject subject'
        'message message message'
        'submit submit submit'; */
        grid-template-areas: 
        'statement'
        'name'
        'email'
        'subject'
        'message'
        'submit'
    }

    .contact-form input{
        width: 100%;
        /* padding: 0.25em 0; */
        box-sizing: border-box;
    }

    .contact-form textarea{
        width: 100%;
        /* padding: 0.25em 0; */
        box-sizing: border-box;
    }

    .contact-statement{
        border-left: none;
        border-bottom-left-radius: 0;
        border-bottom: 1px solid var(--dark1);
        padding: 0 0 .5em 0; 
    }
}

.blog-post-container{
    box-sizing: border-box; 
    
    text-align: left;

    padding: 1em 2em;

    word-wrap: break-word;

}

img{
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

