

/* CONTACT FORM GENERAL STYLES */
.contact-form{
    display: grid;

    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(3, auto);

    grid-template-areas: 
    'name statement statement'
    'email statement statement'
    'subject subject .'
    'message message message'
    'submit . .';

    text-align: left;
}

.contact-form input{
    width: calc(100% - 2em);
    /* border: 2px solid black; */
    border: none;
    box-shadow: 5px 5px 20px var(--grey);
    border-radius: 1px;
    padding: .5em;

    font-size: 1.3em;
}

.contact-form label h3{
    margin: 1em 0 -.5em;
}

.contact-form input[type="submit"]{
    cursor: pointer;
}


/* GRID AREAS */

.contact-statement{
    grid-area: statement;
    margin: 2em auto 0;
    padding: 0 0 1em 1em;
    max-width: 1000px;
    border-left: 1px solid var(--dark1);
}

.contact-form-name{
    grid-area: name;
}

.contact-form-email{
    grid-area: email;
}

.contact-form-subject{
    grid-area: subject;
}

.contact-form-message{
    grid-area: message;
}

.contact-form-submit{
    grid-area: submit;
}



/* MESSAGE CSS */

.contact-form-message textarea{
    width: calc(100% - 3em);
    height: 10em;
    margin-bottom: 1em;

    border-radius: 1px;
    border: none;
    box-shadow: 3px 3px 10px var(--grey);
    padding: 1em;
    font-size: 1.5em;
}



@media only screen and (max-width: 1000px){
    .contact-form{
        /* transform: scale(0.95); */
        grid-template-rows: repeat(6, auto);
        grid-template-columns: auto;
        padding: 0;
        /* grid-template-areas:
        'name name name'
        'email email email'
        'subject subject subject'
        'message message message'
        'submit submit submit'; */
        grid-template-areas: 
        'statement'
        'name'
        'email'
        'subject'
        'message'
        'submit'
    }

    .contact-form input{
        width: 100%;
        /* padding: 0.25em 0; */
        box-sizing: border-box;
    }

    .contact-form textarea{
        width: 100%;
        /* padding: 0.25em 0; */
        box-sizing: border-box;
    }

    .contact-statement{
        border-left: none;
        border-bottom-left-radius: 0;
        border-bottom: 1px solid var(--dark1);
        padding: 0 0 .5em 0; 
    }
}