

.landing-container{
    height: 30em;
    /* background-image: url(''); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: calc(100% - 8em);
    max-width: 1600px;
    margin: 0 auto;

}

@media only screen and (max-width: 850px){
    .landing-container{
        width: 100%;
        margin: 0;
    }
}

/* LANDING TEXT CONTAINER */

.landing-text{
    
    background-color: white;
    height: 100%;
    width: 100%;
    
    animation-name: reveal-background;
    animation-duration: 7s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    animation-timing-function: ease-in; 
    
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes reveal-background{
    from{
        background-color: white;
    }
    to{
        background-color: rgba(212, 212, 212, 0.507);
    }
}

/* LANDING TITLE */

.landing-title, .landing-subtitle{
    font-weight: 300;
    font-family: Arial, Helvetica, sans-serif;
    
    animation-name: title-reveal;
    animation-fill-mode: forwards;
    
    letter-spacing: 2em;
    color: var(--clear);
    
    white-space: nowrap;

}


@media only screen and (min-width: 1001px){
    .landing-title{
        font-size: 4em;
        
        animation-duration: 7s;
        animation-delay: 1s;
        
    }
    
    .landing-subtitle{
        font-size: 1.5em;
        
        animation-duration: 7s;
        animation-delay: 2s;
    }
} 


@keyframes title-reveal{
    0%{
        letter-spacing: 1.25em;
        color: var(--clear);
    }
    100%{
        letter-spacing: 0.5em;
        color: var(--dark);
        text-shadow: 1px 1px 5px var(--grey);
    }
}

@keyframes title-reveal-mobile{
    0%{
        letter-spacing: 0.6em;
        color: var(--clear);
    }
    100%{
        letter-spacing: 0.3em;
        color: var(--dark1);
        text-shadow: 1px 1px 5px var(--grey);
    }
}

@media only screen and (max-width: 1300px){
    .landing-title, .landing-subtitle{
        font-weight: 300;
        font-family: Arial, Helvetica, sans-serif;
        color: var(--clear);
        letter-spacing: 0.25em;
        
        animation-name: title-reveal-mobile;
        animation-duration: 5s;
        animation-delay: 1s;
    }
}

@media only screen and (max-width: 730px){
    .landing-title, .landing-subtitle{
        letter-spacing: 1px;
        color: var(--dark1); 
        animation-name: none;
        white-space: normal;
        text-shadow: 1px 1px 5px var(--grey);

    }
}