
.inner-content-body{
    text-align: left;
    margin: 1em auto 0;
    padding: 0.25em 1em;
    width: 75%;
    min-width: 200px;

    box-shadow: 3px 3px 8px var(--grey) inset;
    border-radius: 4px;
}

@media only screen and (max-width: 1300px){
    .inner-content-body{
        width: 95%;
    }
}

@media only screen and (max-width: 700px){
    .inner-content-body{
        padding: 1em;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; 
    }
}