.project-links a{
    margin: 0 0.5em;
}

.project-info{
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.project-tech-badge{
    height: 2.5em;
    width: 2.5em;
    margin: 0;

    display: inline-block;

    background-size: 100% 100%;
}