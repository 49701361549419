

.bio-box{
    text-align: left;
    min-height: 20em;
}

.bio-version{
    /* text-align: center; */
}

.headshot-image{
    width: 100%;
    max-width: 12em;
    border-radius: 10px;
    float: left;
    
    margin: 1em 1em 0;
}

.social-icon{
    height: 1.5em;
    width: 1.5em;
    margin: 0 0.5em 0 0;

    display: inline-block;

    cursor: pointer;
}

@media only screen and (max-width: 700px){
    .headshot-image{
        float: none;
        display: block;
        width: 100%;
        margin: 0 auto;
    }
}